<template>
	<header>
				<Greeting></Greeting>
				<h1 title="Studio Orkana39">
					<a href="#home" id="home-btn">
						<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" viewBox="0 0 374.17 39.69">
							<g>
								<path :style="{ fill: currentColor }" class="st0 svg" d="M365.37,11.12c-1.44,0-2.31-0.99-2.31-2.73c0-1.68,0.87-2.73,2.31-2.73s2.31,1.05,2.31,2.73
									c0,0.75-0.18,1.35-0.45,1.8C366.81,10.76,366.18,11.12,365.37,11.12 M365.34,0.53c-4.68,0-8.16,3.03-8.16,7.65
									c0,3.93,2.58,6.69,6.24,7.41l-5.4,7.71h6.93l6.87-10.14c1.05-1.59,1.74-3.03,1.74-4.98C373.56,3.56,370.05,0.53,365.34,0.53
									 M347.46,0.53c-4.77,0-7.32,2.58-7.38,6.84l5.79,0.33c0-1.35,0.57-2.04,1.56-2.04c0.96,0,1.47,0.57,1.47,1.77
									c0,1.23-0.78,1.92-1.86,1.92h-2.31v4.83h2.37c1.23,0,2.07,0.72,2.07,2.1c0,1.47-0.57,2.22-1.77,2.22c-1.23,0-1.8-0.84-1.8-2.19
									l-5.79,0.33c0.03,4.26,2.67,6.99,7.62,6.99c4.44,0,7.56-2.61,7.56-7.17c0-2.34-0.99-4.02-2.19-4.74c1.05-0.99,1.92-2.04,1.92-4.35
									C354.72,3.23,352.11,0.53,347.46,0.53 M323.07,18c3.46,0,5.86,2.35,5.86,6.34v1.1c0,4.03-2.4,6.34-5.86,6.34c-3.5,0-6-2.45-6-6.86
									S319.56,18,323.07,18 M321.92,12c-7.01,0-11.95,5.09-11.95,12.91c0,7.78,4.94,12.91,11.95,12.91c3.5,0,6.1-1.39,7.39-3.07v2.54
									h6.58V12.48h-6.58v2.59C328.01,13.34,325.42,12,321.92,12 M296.14,12c-3.36,0-5.57,1.3-6.67,3.02v-2.54h-6.62V37.3h7.01V23.52
									c0-2.98,1.82-4.99,4.61-4.99c2.69,0,4.37,1.78,4.37,4.94V37.3h7.01V22.13C305.84,15.7,301.85,12,296.14,12 M263.55,18
									c3.46,0,5.86,2.35,5.86,6.34v1.1c0,4.03-2.4,6.34-5.86,6.34c-3.5,0-6-2.45-6-6.86S260.04,18,263.55,18 M262.4,12
									c-7.01,0-11.95,5.09-11.95,12.91c0,7.78,4.94,12.91,11.95,12.91c3.5,0,6.1-1.39,7.39-3.07v2.54h6.58V12.48h-6.58v2.59
									C268.49,13.34,265.9,12,262.4,12 M232.73,21.31V0h-7.01v37.3h7.01v-6.58l2.21-2.4l6.48,8.98h8.5l-10.03-14.21l9.36-10.61h-8.5
									L232.73,21.31z M221.65,12.24c-3.79,0-5.76,1.44-6.82,3.07v-2.83h-6.77V37.3h7.01V24.72c0-3.41,2.54-5.71,6.58-5.71V12.24z
									 M184.11,7.44c6.48,0,11.04,4.46,11.04,11.9c0,7.39-4.56,11.9-11.04,11.9c-6.58,0-11.14-4.51-11.14-11.9
									C172.97,11.9,177.53,7.44,184.11,7.44 M184.11,0.86c-10.8,0-18.67,7.2-18.67,18.48s7.87,18.48,18.67,18.48
									c10.7,0,18.62-7.2,18.62-18.48S194.81,0.86,184.11,0.86 M137.89,18c3.6,0,6.1,2.54,6.1,6.91c0,4.32-2.5,6.86-6.1,6.86
									c-3.6,0-6.1-2.54-6.1-6.86C131.79,20.54,134.29,18,137.89,18 M137.89,12c-7.58,0-13.2,5.09-13.2,12.91
									c0,7.78,5.62,12.91,13.2,12.91s13.2-5.14,13.2-12.91C151.09,17.09,145.47,12,137.89,12 M119.55,12.48h-7.01V37.3h7.01V12.48z
									 M116.05,0.14c-2.26,0-4.18,1.87-4.18,4.22c0,2.3,1.82,4.18,4.18,4.18c2.35,0,4.22-1.87,4.22-4.18
									C120.27,2.02,118.4,0.14,116.05,0.14 M93.25,18c3.46,0,5.86,2.35,5.86,6.34v1.1c0,4.03-2.4,6.34-5.86,6.34c-3.5,0-6-2.45-6-6.86
									S89.74,18,93.25,18 M106.06,37.3V0H99.1v14.64C97.81,13.15,95.31,12,92.1,12c-7.01,0-11.95,5.09-11.95,12.91
									c0,7.78,4.94,12.91,11.95,12.91c3.5,0,6.1-1.39,7.39-3.07v2.54H106.06z M75.53,37.3V12.48h-7.01V26.5c0,2.78-1.54,4.8-4.22,4.8
									c-2.74,0-3.98-1.68-3.98-4.7V12.48h-7.01v15.6c0,6.05,3.46,9.74,9.17,9.74c3.5,0,5.47-1.49,6.43-3.02v2.5H75.53z M43.23,18.53h5.86
									v-6.05h-5.81l0.05-8.21h-7.01l-0.05,8.21h-4.56v6.05h4.51l-0.05,10.9c-0.05,5.76,3.65,8.35,9.02,8.35c1.92,0,2.88-0.14,3.89-0.29
									v-6.24c-0.53,0.1-1.15,0.24-2.16,0.24c-1.97,0-3.74-1.01-3.74-3.74L43.23,18.53z M15.58,0.86c-8.59,0-12.77,5.09-12.77,10.99
									c0,6.96,5.38,8.78,11.86,10.42c4.8,1.2,7.39,2.21,7.39,4.94c0,2.93-2.35,4.18-5.66,4.18c-3.89,0-6.72-2.21-6.86-5.47L2,26.3
									c0.1,7.44,6.1,11.52,14.4,11.52c7.2,0,13.2-3.36,13.2-10.99c0-6.38-4.85-8.64-12.19-10.51c-4.94-1.15-7.01-1.92-7.01-4.7
									c0-2.5,1.92-4.32,5.33-4.32s5.52,1.97,5.57,4.99l7.58-0.38C28.69,4.99,23.36,0.86,15.58,0.86"></path>
							</g>
						</svg>
					</a>
				</h1>
			<ul class="menu">
				<li><a href="mailto:studio@orkana39.pl">studio@orkana39</a></li>
				<li><a @click.self.prevent="$emit('clientlistevent')" id="client-list-btn" href="#0">profil</a></li>
				<li><a href="https://www.behance.net/orkana39" target="_blank">portfolio</a></li>
				<li><a href="https://www.instagram.com/orkana39/" target="_blank">instagram</a></li>
			</ul>
		</header>
</template>

<script>
	import Greeting from './o38Greeting.vue';

	export default {
		props: [ 'currentColor' ],
		components: { Greeting }
	}
</script>

<style scoped lang="scss">
@import '../scss/_mixins.scss';

header {
	margin-bottom: 2rem;
	h1 {
		font-weight: bold;
		width: 60%;
		margin-bottom: 1rem;
		line-height: 0;

		a:hover {
			border: none;
		}
	}

	ul.menu {
		@include inline-list;
		font-size: 18px;
		font-weight: bold;
		margin-left: 5px;
		padding-bottom: 1rem;
		li {
			margin-right: 3rem;
		}
	}

	.st0 {
		fill: inherit;
	}
}

@media all and (max-width: 767px) {
	header {
		p.hello {
			font-size: 12px;
		}
		h1 {
			width: 80%;
		}
		ul.menu {
			display: block;
		}
	}
}
</style>
