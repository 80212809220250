import backgroundImages from '../images/backgrounds/*.jpg'

export const backgrounds = {
	bcgr0: {
		file: '',
		file_sml: '',
		color: 'black',
		backgroundColor: 'white'
	},
	bcgr1: {
		file: backgroundImages.czteropak,
		file_sml: backgroundImages.czteropak_sml,
		color: 'grey',
		backgroundColor: 'black'
	},
	bcgr2: {
		file: backgroundImages.infomnwr,
		file_sml: backgroundImages.infomnwr_sml,
		color: 'cornflowerblue'
	},
	bcgr3: {
		file: backgroundImages.mawww2,
		file_sml: backgroundImages.mawww2_sml,
		color: '#84a3ff'
	},
	bcgr4: {
		file: backgroundImages.nomus,
		file_sml: backgroundImages.nomus_sml,
		color: 'blueviolet'
	},
	bcgr5: {
		file: backgroundImages.dzikie,
		file_sml: backgroundImages.dzikie_sml,
		color: 'rgb(252, 48, 214)',
		backgroundColor: 'darkslateblue'
	},
	bcgr6: {
		file: backgroundImages.werk,
		file_sml: backgroundImages.werk_sml,
		color: 'white',
		backgroundColor: 'black'
	},
	bcgr7: {
		file: '',
		file_sml: '',
		color: 'black'
	}
}
